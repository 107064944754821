<!--添加题目-->
<template>
    <div class="main">
        <div>
            <div style="font-size: 26px; font-weight: bold;display:flex">
                <div style="color:#C8C8C8;cursor: pointer;" @click="goback">&lt;调查问卷</div>
                <div>/添加试题</div>
            </div>

            <div style="display: flex;align-items:center;margin: 20px 0;">
                <div style="font-size: 20px;font-weight: bold;">问卷内容</div>
                <el-button
                    type="primary"
                    style="height: 34px;line-height: 10px;"
                    @click="dialogVisibleshow"
                >添加试题</el-button>
            </div>
            <div style="background: rgb(251, 246, 246);padding: 20px;">
                <div v-for="(item, index) in list" :key="index">
                    <div style="margin: 30px 0;display: flex;align-items: center;">
                        {{ index + 1 }}、{{ item.title }}
                        <span v-if="item.type == 1">(选择题)</span>
                        <span v-else>(填空题)</span>
                        <img
                            src="../../images/edit.png"
                            @click="edit(item)"
                            style="width: 20px;height:20px;margin: 0 20px;"
                            alt
                        />
                        <img
                            src="../../images/del.png"
                            @click="del(item)"
                            style="width: 20px;height:20px;"
                            alt
                        />
                    </div>
                    <div style="display: flex;">
                        <div
                            style="margin: 0 20px;"
                            v-for="(item2, index) in item.surveyOptionInfoList"
                            :key="index"
                        >{{ item2.title }}</div>
                    </div>
                </div>
            </div>
            <!-- <div style="margin: 50px 0;display: flex;justify-content: center;">
                <el-button type="primary" style="margin-right: 20px;" @click="addquestionnaire">添加问卷</el-button>
                <el-button type="primary">取消</el-button>
            </div> -->
        </div>
        <el-dialog
            title="添加试题"
            :visible.sync="dialogVisible"
            width="80%"
            :before-close="handleClose"
        >
            <div class="title">
                <div class="titleleft">
                    <div style="align-items: center">
                        <div class="line"></div>
                        <div>题目设置</div>
                    </div>
                    <el-form label-position="right" style="margin-top: 40px">
                        <el-form-item label="问卷类型：">
                            <el-select v-model="value" placeholder="请选择" :disabled="edits">
                                <el-option
                                    v-for="item in options"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="问卷题目：">
                            <el-input
                                type="textarea"
                                :rows="5"
                                v-model="textarea"
                                :style="{ width: value != 4 && value != 5 ? '342px' : '500px' }"
                            ></el-input>
                        </el-form-item>

                        <!-- <el-form-item label="考试等级：">
                        <el-select v-model="levelId" placeholder="请选择">
                            <el-option
                                v-for="item in levelLists"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            ></el-option>
                        </el-select>
                        </el-form-item>-->

                        <!-- <el-form-item label="操作：" v-if="value == 3">
                        <el-button @click="insterLine">插入下划线</el-button>
                        </el-form-item>-->
                        <el-form-item label="参考答案：" v-if="value == 4 || value == 5">
                            <el-input
                                type="textarea"
                                :rows="5"
                                v-model="textarea2"
                                :style="{ width: value != 4 && value != 5 ? '342px' : '500px' }"
                            ></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div style="flex-direction: column" v-if="value != 2">
                    <div style="align-items: center">
                        <div class="line"></div>
                        <div style="display: flex; align-items: baseline">
                            <div>选项设置</div>
                        </div>
                    </div>
                    <div style="flex-direction: column">
                        <el-checkbox-group
                            v-model="checkList"
                            style="display: flex; flex-direction: column"
                            v-if="value == 1 || value == ''"
                        >
                            <div
                                style="display: flex; margin-top: 26px; align-items: center"
                                v-for="(item, index) of selectData"
                                :key="index"
                            >
                                <el-checkbox
                                    :label="item.label"
                                    style="display: flex; align-items: center"
                                >
                                    <div style="margin: 0 13px 0 27px">{{ item.name }}</div>
                                    <el-input
                                        v-model="item.value"
                                        placeholder="请填入选项内容"
                                        style="width: 290px"
                                    ></el-input>
                                </el-checkbox>
                                <img
                                    v-if="index + 1 == selectData.length && index > 1"
                                    src="../../images/reduce.png"
                                    alt
                                    style="width: 22px; height: 23px; margin-left: 10px"
                                    @click="dele(item,index)"
                                />
                            </div>
                            <div style="margin-top: 12px; margin-left: 200px">
                                <img
                                    src="../../images/add.png"
                                    alt
                                    style="width: 22px; height: 23px"
                                    @click="add"
                                    v-if="selectData.length < 6"
                                />
                            </div>
                        </el-checkbox-group>

                        <div
                            style="display: flex; margin-top: 26px; flex-direction: column;font-weight: normal"
                            v-for="(item, index) of selectData"
                            v-if="value == 3"
                            :key="index"
                        >
                            <div style="align-items:center">
                                <div style="margin: 0 13px 0 27px">{{ item.name }}</div>
                                <el-input
                                    v-model="item.value"
                                    placeholder="请填入选项内容"
                                    style="width: 290px"
                                ></el-input>
                                <img
                                    v-if="index + 1 == selectData.length && index > 0"
                                    src="../../images/reduce.png"
                                    alt
                                    style="width: 22px; height: 23px; margin-left: 10px"
                                    @click="dele(item,index)"
                                />
                            </div>
                            <div
                                style="margin-top: 12px; margin-left: 200px"
                                v-if="index + 1 == selectData.length && index < 5"
                            >
                                <img
                                    src="../../images/add.png"
                                    alt
                                    style="width: 22px; height: 23px"
                                    @click="add"
                                />
                            </div>
                        </div>
                        <div
                            style="display: flex; margin-top: 26px; align-items: center"
                            v-for="(item, index) of selectData"
                            v-if="value == 2"
                            :key="index"
                        >
                            <el-radio
                                v-model="radio"
                                :label="item.label"
                                style="display: flex; align-items: center"
                            >
                                <div style="margin: 0 13px 0 27px">{{ item.name }}</div>
                            </el-radio>
                            <img
                                v-if="index + 1 == selectData.length && index > 2"
                                src="../../images/reduce.png"
                                alt
                                style="width: 22px; height: 23px"
                                @click="dele(item,index)"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <el-form label-position="right" v-if="value == 5">
                <el-form-item label="操作：">
                    <input
                        type="file"
                        @change="uploadimg"
                        accept="image/*"
                        id="file"
                        ref="file"
                        style="color:#fff"
                    />
                </el-form-item>
                <el-form-item label="图片：" style="color:red" v-if="imglist.length > 0">
                    <div style="display:flex">
                        <div
                            style="position:relative;width:200px"
                            v-for="(item, index) in imglist"
                            alt
                            :key="index"
                        >
                            <div
                                style="width:100%;height:100%;position:absolute;background: rgba(0,0,0,0.1);"
                            ></div>
                            <img :src="item" alt style="width: 200px" />
                            <img
                                @click="deleteImg(index)"
                                src="../../images/delete.png"
                                alt
                                style="z-index:999;position: absolute;width: 25px;height: 25px;left: 50%;top: 50%;transform: translate(-50%, -50%);width:25px;height:25px"
                            />
                        </div>
                    </div>
                </el-form-item>
            </el-form>

            <span
                slot="footer"
                class="dialog-footer"
                style="display: flex;justify-content: center;"
            >
                <el-button @click="dialogVisible = false">取 消</el-button>
                
                <el-button type="primary" @click="submit2" v-if="id">添 加</el-button>
                <el-button type="primary" @click="submit" v-else>添 加</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { questiontypeList, levelList, qssaveOrUpdate, getCosCredit, addPaper, AddPaperList, surveyquestionList, delequestion,updatePaper3 } from "../../apis/index";
var COS = require('cos-js-sdk-v5');
export default {
    data() {
        return {
            edits:false,
            arr:[],
            id:'',
            list: [],
            surveyId: '',
            courseId: 3,//课程id
            typeList: [
                {
                    id: 1,
                    name: '小程序'
                }, {
                    id: 2,
                    name: '课程包'
                }
            ],
            type: '',//问卷类型
            title: '',//问卷标题
            pushRangeList: [
                {
                    id: 100,
                    name: '全量'
                }, {
                    id: 170,
                    name: '70%'
                },
                {
                    id: 150,
                    name: '50%'
                },
                {
                    id: 3,
                    name: '私域'
                },
                {
                    id: 13,
                    name: '公域'
                }
            ],
            pushRange: '',//推送范围
            timeList: [],//问卷生效时间
            value1: [],
            imglist: [],
            textarea2: '',
            textarea: '',
            fileList: [],
            dialogImageUrl: "",
            dialogVisible: false,
            disabled: false,
            textarea: "",
            checkList: [],
            radio: "1",
            options: [
                {
                    id: 1,
                    name: '选择题'
                }, {
                    id: 2,
                    name: '填空题'
                }

            ],//题目类型列表
            value: 1,
            levelLists: [],//等级列表
            levelId: '',//等级值
            selectData: [
                {
                    label: "1",
                    name: "A",
                    value: "",
                },
                {
                    label: "2",
                    name: "B",
                    value: "",
                },
                {
                    label: "3",
                    name: "C",
                    value: "",
                },
            ],
        };
    },
    watch: {
        // value(newdata, olddate) {
        //     console.log(newdata);
        //     if (newdata == 1) {
        //         this.selectData = [
        //             {
        //                 label: "1",
        //                 name: "A",
        //                 value: "",
        //             },
        //             {
        //                 label: "2",
        //                 name: "B",
        //                 value: "",
        //             },
        //             {
        //                 label: "3",
        //                 name: "C",
        //                 value: "",
        //             },
        //         ];
        //     } else if (newdata == 2) {
        //         this.selectData = [
        //             {
        //                 label: "1",
        //                 name: "第1空",
        //                 value: "",
        //             },
        //         ];
        //     }
        //     // else if (newdata == 2) {
        //     //     this.selectData = [
        //     //         {
        //     //             label: "1",
        //     //             name: "正确",
        //     //         },
        //     //         {
        //     //             label: "2",
        //     //             name: "错误",
        //     //         },
        //     //     ];
        //     // }
        // },
        checkList() {
            this.selectData.forEach(item => {
                item.code = 0
            })
        },
        radio() {
            this.selectData.forEach(item => {
                item.code = 0
            })
        },
    },
    created() {
        // console.log(this.$route.meta.keepAlive)
        // console.log(JSON.parse(this.$route.query.row))
        // this.questype()
        this.surveyId = JSON.parse(this.$route.query.row).id
        this.quslist()
        // this.leave()
    },

    methods: {
        dialogVisibleshow(){//添加试题
            this.dialogVisible = true
            this.edits=false
            this.value = 1
            this.textarea = ''
            this.id=''
            this.selectData=[
                {
                    label: "1",
                    name: "A",
                    value: "",
                },
                {
                    label: "2",
                    name: "B",
                    value: "",
                },
                {
                    label: "3",
                    name: "C",
                    value: "",
                },
            ]
        },
        async del(row) {//删除
            const { data } = await delequestion({ questionId: row.id })
            console.log(data)
            if (data.code == 200) {
                this.$message.success(data.msg)
                this.quslist()
            }
        },
        edit(details) {//编辑
            console.log(details)
            this.dialogVisible = true
            this.value = details.type
            this.textarea = details.title
            this.id=details.id
            this.edits=true
            // console.log(details)

            if (details.type == 1) {//选择题
                let arr = details.surveyOptionInfoList
                let obj = arr.map((item, index) => {
                    return {
                        label: index,
                        name: item.title.slice(0, 1),
                        value: item.title.slice(1),
                        id: item.id,
                        surveyInfoId: item.surveyInfoId,
                        surveyQuestionInfoId: item.surveyQuestionInfoId,
                    }
                })
                this.selectData=obj
                // let rightKey=details.rightKey
                // let arr2=rightKey.split('')
                // let selectData=this.selectData
                // arr2.map((item,index)=>{
                //     selectData.forEach((item2,index2)=>{
                //       if(index==index2 && item==1){
                //         item2.checked=true
                //       }
                //     })
                // })
                // this.id=details.id
                // console.log(obj)
            }
        },
        async quslist() {//问题列表
            const { data } = await surveyquestionList({ surveyId: this.surveyId })
            console.log(data.data)
            if (data.code == 200) {
                this.list = data.data
            }
        },
        async addquestionnaire() {//添加问卷
            const params = {
                courseId: this.courseId,
                endTime: this.timeList[1],
                picUri: this.imglist,
                pushRange: this.pushRange,
                startTime: this.timeList[0],
                title: this.title,
                type: this.type
            }
            const { data } = await addPaper(params)
            if (data.code == 200) {
                
                this.$message(data.msg);
                setTimeout(() => {
                    this.$router.go(-1)
                }, 100)
            } else {
                this.$message.error(data.msg);
            }
        },
        handleClose() {
            this.dialogVisible = false
        },
        deleteImg() {//删除图片
            this.imglist = ''
        },
        insterLine() {
            this.textarea = this.textarea + '____'
        },
        uploadimg(e) {//上传图片
            if (this.$refs['file'].files[0]) {
                let selectFileobj = this.$refs['file'].files[0]
                this.upload(selectFileobj)
            }
        },
        upload(selectFileobj) {
            let that = this
            let name = selectFileobj.name
            // if (that.levelId == '') {
            //     that.$message.error('请先选择等级')
            //     document.getElementById('file').value = ''
            //     return
            // }
            let filename = 'content/resource/surveyimage/' +
                name.slice(0, name.length - 4) +
                new Date().getTime() +
                name.slice(-4);
            console.log(filename)
            var cos = new COS({
                getAuthorization: function (options, callback) {
                    getCosCredit().then(result => {
                        var data = result.data.data;
                        var credentials = data && data.credentials;
                        if (!data || !credentials) return console.error('credentials invalid');
                        sessionStorage.setItem('bucket', data.bucket)
                        callback({
                            TmpSecretId: credentials.tmpSecretId,
                            TmpSecretKey: credentials.tmpSecretKey,
                            XCosSecurityToken: credentials.sessionToken,
                            // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
                            StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
                            ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000900
                        });
                    })
                }
            });
            cos.options.getAuthorization('', function (obj) {
                cos.putObject({
                    Bucket: sessionStorage.getItem('bucket'), /* 必须 */
                    Region: 'ap-nanjing',     /* 存储桶所在地域，必须字段 */
                    Key: filename,              /* 必须 */
                    StorageClass: 'STANDARD',
                    Body: selectFileobj, // 上传文件对象
                    onProgress: function (progressData) {
                        // console.log(JSON.stringify(progressData));
                        console.log(progressData)
                    }
                }, function (err, data) {
                    console.log(err || data);
                    if (data) {
                        let url = data.Location.replace(/content-1308336073.cos.ap-nanjing.myqcloud.com/g, 'https://sihuankangyuan.cn')
                        that.imglist = url
                        // that.imglist.push(url)
                        // console.log(that.imglist)
                    }
                });
            })

        },
        goback() {
            this.$router.go(-1)
        },
        async submit() {//保存

            if (this.value == 1) {//选择题
                let selectData = this.selectData
                selectData.forEach(item => {
                    item.title = item.name + item.value
                })
                const params = {
                    surveyId: this.surveyId,
                    surveyQuestionWithOptionsSaveDtoList: [
                        {
                            surveyOptionSaveDtoList: selectData
                            ,
                            surveyQuestionSaveDto: {
                                title: this.textarea,
                                type: this.value
                            }
                        }
                    ]

                }
                const { data } = await AddPaperList(params)
                console.log(data)
                if (data.code == 200) {
                    this.$message.success(data.msg)
                    this.dialogVisible=false
                    this.quslist()
                } else {
                    this.$message.error(data.msg)
                }

            } else if (this.value == 2) {//填空题
                const params = {
                    surveyId: this.surveyId,
                    surveyQuestionWithOptionsSaveDtoList: [
                        {
                            surveyOptionSaveDtoList: [
                                // {
                                //     title:''
                                // }
                            ],
                            surveyQuestionSaveDto: {
                                title: this.textarea,
                                type: this.value
                            }
                        }
                    ]

                }
                const { data } = await AddPaperList(params)
                console.log(data)
                if (data.code == 200) {
                    this.$message.success(data.msg)
                    this.dialogVisible=false
                    this.quslist()
                } else {
                    this.$message.error(data.msg)
                }
            }
        },
       async submit2(){//更新
           if (this.value == 1) {//选择题
                let selectData = this.selectData
                selectData.forEach(item => {
                    item.title = item.name + item.value
                })
                const params={
                    surveyInfoId:this.surveyId,
                    removeOptionIds:this.arr,
                    question:{
                        id:this.id,
                        title:this.textarea
                    },
                    optionList:this.selectData
                }
                const { data } = await updatePaper3(params)
                console.log(data)
                if (data.code == 200) {
                    this.$message.success(data.msg)
                    this.dialogVisible=false
                    this.quslist()
                } else {
                    this.$message.error(data.msg)
                }

            } else if (this.value == 2) {//填空题
                let selectData = this.selectData
                selectData.forEach(item => {
                    item.title = item.name + item.value
                })
                const params={
                    surveyInfoId:this.surveyId,
                    // removeOptionIds:this.arr,
                    question:{
                        id:this.id,
                        title:this.textarea
                    },
                    // optionList:this.selectData
                }
                const { data } = await updatePaper3(params)
                console.log(data)
                if (data.code == 200) {
                    this.$message.success(data.msg)
                    this.dialogVisible=false
                    this.quslist()
                } else {
                    this.$message.error(data.msg)
                }
            }
        },
        async leave() {//获取等级列表
            const { data } = await levelList({ roleId: 3 })
            if (data.code == 200) {
                this.levelLists = data.data
            } else {
                this.$message.error(data.msg)
            }
        },
        async questype() {//题目类型列表
            const { data } = await questiontypeList();
            let qslist = data.data
            this.options = qslist;

        },
        handChange(file, fileList) {
            this.fileList = fileList
        },
        handleRemove(file) {
            let uid = file.uid
            let fileList = this.fileList
            fileList.map((item, index) => {
                if (item.uid == uid) {
                    this.fileList.splice(index, 1)
                }
            })
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handleDownload(file) {
            console.log(file);
        },
        add() {
            if (!this.value) {
                this.$message.error('请先选择题目类型')
                return
            }
            //添加
            if (this.value == 1) {
                let selectData = this.selectData;
                let res = selectData.some((item) => {
                    return item.name == "D";
                });
                if (res) {
                    let res = selectData.some((item) => {
                        return item.name == "E";
                    });
                    if (res) {
                        let res = selectData.some((item) => {
                            return item.name == "E";
                        });
                        if (res) {
                            let res = selectData.some((item) => {
                                return item.name == "F";
                            });
                            if (!res) {
                                this.selectData.push({
                                    label: "6",
                                    name: "F",
                                    value: "",
                                });
                            }
                        }
                    } else {
                        this.selectData.push({
                            label: "5",
                            name: "E",
                            value: "",
                        });

                        console.log(this.selectData);
                    }
                } else {
                    this.selectData.push({
                        label: "4",
                        name: "D",
                        value: "",
                    });
                    console.log(this.selectData);
                }
            } else if (this.value == 3) {
                let selectData = this.selectData;
                selectData.push({
                    label: selectData.length + 1,
                    name: `第${selectData.length + 1}空`,
                    value: "",
                })
                this.selectData = selectData
                console.log(selectData)
            }
            // else if (this.value == 3) {
            //   let selectData = this.selectData;
            //   let res = selectData.some((item) => {
            //     return item.name == "第二空";
            //   });
            //   if (res) {
            //     let res = selectData.some((item) => {
            //       return item.name == "第三空";
            //     });
            //     if (res) {
            //       let res = selectData.some((item) => {
            //         return item.name == "第四空";
            //       });
            //       if (res) {
            //         let res = selectData.some((item) => {
            //           return item.name == "第五空";
            //         });
            //         if (res) {
            //           let res = selectData.some((item) => {
            //             return item.name == "第五空";
            //           });
            //           if (res) {
            //             this.selectData.push({
            //               label: "6",
            //               name: "第六空",
            //               value: "",
            //             });
            //           }
            //         } else {
            //           this.selectData.push({
            //             label: "5",
            //             name: "第五空",
            //             value: "",
            //           });
            //         }
            //       } else {
            //         this.selectData.push({
            //           label: "4",
            //           name: "第四空",
            //           value: "",
            //         });
            //       }
            //     } else {
            //       this.selectData.push({
            //         label: "3",
            //         name: "第三空",
            //         value: "",
            //       });

            //       console.log(this.selectData);
            //     }
            //   } else {
            //     this.selectData.push({
            //       label: "2",
            //       name: "第二空",
            //       value: "",
            //     });
            //     console.log(this.selectData);
            //   }
            // }

        },
        dele(item,ind) {
            this.arr.push(item.id)
            this.selectData.splice(ind, 1);
        },
    },
};
</script>

<style lang='less' scoped>
.main {
    background: #fff;
    margin: 19px 0;
    padding: 23px;
    border-radius: 14px;
}
.title {
    display: flex;
    // justify-content: space-between;
    align-items: flex-start;
    div {
        display: flex;
    }
    .line {
        width: 9px;
        height: 22px;
        border-radius: 6px;
        background: #409eff;
        margin-right: 11px;
    }
}
.title > div {
    width: 50%;
    margin: 29px 0;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    //   align-items: center;
}
.titleleft {
    display: flex;
    flex-direction: column;
}
/deep/.el-form-item__label {
    width: 100px;
}
/deep/.el-radio__label,
/deep/.el-checkbox__label {
    display: flex;
    align-items: center;
}
.ft {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
}
</style>
